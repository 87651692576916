<template>
  <div class="company">
    <div class="actionBar">
      <a-input-search
        size="large"
        v-model="listParams.keyword"
        placeholder="请输入关键词搜索"
        enter-button="查询"
        style="width: 350px"
        allowClear
        @search="onSearch"
      />
      <a-button
        icon="plus"
        type="primary"
        @click="showModal"
        size="large"
        v-if="handLists.top.indexOf('add') != -1"
      >
        新增企业</a-button
      >
    </div>
    <a-modal v-model="PVWVisible" title="图片预览" :footer="null">
      <img
        :src="PVWImgUrl"
        style="maxWidth:100%;maxHeight:100%;margin:0 auto"
      />
    </a-modal>

    <a-table
      :columns="columns"
      :data-source="data"
      rowKey="id"
      :pagination="{
        total: count,
        current: listParams.page,
        pageSize: listParams.limit,
        showTotal: (total) => `共 ${total} 条`,
      }"
      @change="pageChange"
    >
      <span slot="list_order" slot-scope="text, record">
        <a-input-number
          v-model="record.list_order"
          @blur="list_orderChange(record)"
          :min="0"
          @focus="saveOldNum(text)"
        ></a-input-number>
      </span>


      <span slot="collect_count" slot-scope="text, record">
        <a-input
          v-model="record.collect_count"
          style="width:80px"
          @blur="collectCountChangeVal(record)"
        >
        </a-input>
      </span>


      <span slot="img_url" slot-scope="text">
        <a v-if="text">
          <img
            :src="text"
            alt="图片地址发生错误"
            :style="{ 'max-width': '100px', 'max-height': '100px' }"
            @click="pvwShow(text)"
          />
        </a>
        <span v-else>
          暂无
        </span>
      </span>
      <span slot="status" slot-scope="text, record">
        <a-tag
          :color="text == 1 ? 'green' : '#999'"
          :title="record.status == 1 ? '点击禁用' : '点击启用'"
          @click="statusChange(record)"
        >
          {{ text == 1 ? "启用" : "禁用" }}
        </a-tag>
        <a-tag
          :color="record.recommended == 1 ? 'green' : '#999'"
          :title="record.recommended == 1 ? '取消推荐' : '点击推荐'"
          @click="recommendedChangeVal(record)"
        >
          {{ record.recommended == 1 ? "已推荐" : "未推荐" }}
        </a-tag>
      </span>
      <span slot="action" slot-scope="text, record">
        <span v-if="handLists.right.indexOf('edit') != -1">
          <FontIcon
            @click.native="editMenu(record)"
            title="修改"
            type="icon-xiugai"
            size="22"
          ></FontIcon>
          <a-divider type="vertical" />
        </span>

        <span v-if="handLists.right.indexOf('del') != -1">
          <a-popconfirm
            title="确定删除？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="confirm(record)"
          >
            <FontIcon title="删除" type="icon-lajitong" size="22"></FontIcon>
          </a-popconfirm>
          <a-divider type="vertical" />
        </span>

        <FontIcon
          @click.native="copyLink(record.share_url)"
          title="点击复制链接"
          type="icon-lianjiefenxiang"
          size="22"
        ></FontIcon>
        <div style="height:5px"></div>

        <!-- <a-button
          v-if="handLists.right.indexOf('del') != -1"
          type="primary"
          size="small"
          @click="goAccount(record)"
        >
          账号管理
        </a-button> -->

        <!-- <span v-if="handLists.right.indexOf('setStatus') != -1">
          <div style="height:5px"></div>
          <a-switch
            :title="record.status == 1 ? '点击禁用' : '点击启用'"
            size="small"
            checked-children="启"
            un-checked-children="禁"
            :checked="record.status == 1"
            @change="statusChange(record)"
          />

          <a-divider type="vertical" />
          <a
            :title="record.recommended == 1 ? '取消推荐' : '推荐'"
            @click="recommendedChangeVal(record)"
          >
            <a-icon
              type="like"
              :style="{
                color: record.recommended == 1 ? '#FFB21D' : '#999',
                fontSize: '20px',
              }"
            />
          </a>
        </span> -->
      </span>
    </a-table>
  </div>
</template>

<script>
import {
  companyIndex,
  companyDelete,
  companySetStatus,
  companyChangeVal,
  uploadImage,
  companyChangeCollect,
} from "@/apis/index";
const columns = [
  {
    title: "排序",
    dataIndex: "list_order",
    scopedSlots: { customRender: "list_order" },
  },
  {
    title: "ID",
    dataIndex: "id",
    scopedSlots: { customRender: "id" },
    align: "center",
  },
  {
    title: "企业名称",
    dataIndex: "company_name",
    scopedSlots: { customRender: "company_name" },
  },
  {
    title: "简介",
    dataIndex: "desc",
    scopedSlots: { customRender: "desc" },
    width: 640,
  },
  {
    // align: "center",
    title: "关注数",
    dataIndex: "collect_count",
    scopedSlots: { customRender: "collect_count" },
  },

  {
    title: "封面图",
    dataIndex: "img_url",
    scopedSlots: { customRender: "img_url" },
  },
  {
    title: "设置状态",
    dataIndex: "status",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
  },
];

export default {
  data() {
    return {
      uploadImage,
      columns,
      data: [],
      visible: false,
      confirmLoading: false,
      modalTitle: "",
      addFromData: {},
      listParams: {
        page: 1,
        limit: 10,
        keyword: "",
      },
      count: 0,
      autoExpandParent: true,

      oldNum: "",
      PVWVisible: false,
      PVWImgUrl: "",

      handLists: this.$store.state.menuHandleList,
    };
  },

  computed: {
    handList() {
      return this.$store.state.menuHandleList;
    },
  },
  watch: {
    handList(val) {
      this.handLists.top = val.top ? val.top : [];
      this.handLists.right = val.right ? val.right : [];
    },
  },

  created() {
    this.getCompanyIndex();
  },
  methods: {
    collectCountChangeVal(record){
      companyChangeCollect({
        id: record.id,
        collect_count: record.collect_count,
      }).then((res) => {
        if (res.code == 1) {
          this.$message.success("修改成功");
        }
      });

    },
    goAccount(record) {
      this.$router.push({
        path: "/datamaintenances/Account",
        query: {
          id: record.id,
        },
      });
    },
    pvwShow(text) {
      this.PVWVisible = true;
      this.PVWImgUrl = text;
    },
    copyLink(data) {
      let OrderNumber = data;
      let newInput = document.createElement("input");
      newInput.value = OrderNumber;
      document.body.appendChild(newInput);
      newInput.select();
      document.execCommand("Copy");
      newInput.remove();
      this.$message.success("复制成功");
    },
    saveOldNum(text) {
      this.oldNum = text;
    },
    showModal() {
      this.$router.push({ path: "/datamaintenances/addEditCompany" });
    },
    editMenu(record) {
      this.$router.push({
        path: "/datamaintenances/addEditCompany",
        query: {
          id: record.id,
        },
      });
    },
    confirm(rec) {
      companyDelete({
        id: rec.id,
      }).then((res) => {
        if (res.code == 1) {
          this.$message.success("success");
          this.getCompanyIndex();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    pageChange(a) {
      this.listParams.page = a.current;
      this.getCompanyIndex();
    },
    getCompanyIndex() {
      companyIndex(this.listParams).then((res) => {
        // console.log(res);
        if (res.data.list.length == 0 && this.listParams.page > 1) {
          this.listParams.page--;
          this.getCompanyIndex();
        } else {
          this.data = res.data.list;
          this.count = res.data.count;
        }
      });
    },
    onSearch() {
      this.listParams.page = 1;
      this.getCompanyIndex();
    },
    statusChange(record) {
      if (this.handLists.right.indexOf("setStatus") != -1) {
        companySetStatus({
          ids: record.id,
          status: record.status == 1 ? 2 : 1,
        }).then((res) => {
          if (res.code == 1) {
            this.$message.success("success");
            this.getCompanyIndex();
          }
        });
      } else {
        return this.$message.error("您没有权限");
      }
    },
    recommendedChangeVal(record) {
      if (this.handLists.right.indexOf("setStatus") != -1) {
        companySetStatus({
          ids: record.id,
          status: record.recommended == 1 ? 4 : 3,
        }).then((res) => {
          if (res.code == 1) {
            this.$message.success("success");
            this.getCompanyIndex();
          }
        });
      } else {
        return this.$message.error("您没有权限");
      }
    },
    list_orderChange(record) {
      if (this.oldNum == record.list_order) {
        return false;
      } else {
        companyChangeVal({
          id: record.id,
          change_value: record.list_order,
        }).then((res) => {
          if (res.code == 1) {
            this.$message.success("cuccess");
          }
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.company {
  .actionBar {
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
  }
}
</style>
